import { defineMessages } from 'react-intl'
import {
    ChatPendingMiniNotification,
    OrderMiniNotification,
    PendingTaskMiniNotification
} from './NotificationTypes'
import React from 'react'

defineMessages({
    HAS_PENDING_TASKS: {
        id: 'HAS_PENDING_TASKS',
        description: 'HAS_PENDING_TASKS',
        defaultMessage: 'Has pending tasks!'
    },
    CHAT_PENDING_MESSAGE: {
        id: 'CHAT_PENDING_MESSAGE',
        description: 'CHAT_PENDING_MESSAGE',
        defaultMessage: 'Chat pending with {userName}',
    },
    ORDER_CONFIRMED_MESSAGE: {
        id: 'ORDER_CONFIRMED_MESSAGE',
        description: 'ORDER_CONFIRMED_MESSAGE',
        defaultMessage: 'Order has been confirmed by {userName}',
    },
    ORDER_NOT_CONFIRMED_MESSAGE: {
        id: 'ORDER_NOT_CONFIRMED_MESSAGE',
        description: 'ORDER_NOT_CONFIRMED_MESSAGE',
        defaultMessage: "Order hasn't been confirmed by {userName}",
    },
    ORDER_SENT_MESSAGE: {
        id: 'ORDER_SENT_MESSAGE',
        description: 'ORDER_SENT_MESSAGE',
        defaultMessage: 'Order has been sent by {userName}',
    },
    ORDER_RECEIVED_MESSAGE: {
        id: 'ORDER_RECEIVED_MESSAGE',
        description: 'ORDER_RECEIVED_MESSAGE',
        defaultMessage: '{userName} has received order',
    },
    ORDER_LOST_CONFIRMATION: {
        id: 'ORDER_LOST_CONFIRMATION',
        description: 'ORDER_LOST_CONFIRMATION',
        defaultMessage: 'Order has been set as lost by {userName}',
    },
    ORDER_CANCELLED_MESSAGE: {
        id: 'ORDER_CANCELLED_MESSAGE',
        description: 'ORDER_CANCELLED_MESSAGE',
        defaultMessage: 'Order has been cancelled by {userName}',
    },
    ORDER_VOTED_MESSAGE: {
        id: 'ORDER_VOTED_MESSAGE',
        description: 'ORDER_VOTED_MESSAGE',
        defaultMessage: '{userName} has voted the order',
    },
    PENDING_PICKUP_MESSAGE: {
        id: 'PENDING_PICKUP_MESSAGE',
        description: 'PENDING_PICKUP_MESSAGE',
        defaultMessage: '{userName} has to pick up the order',
    },
    ORDER_EXPIRED_MESSAGE: {
        id: 'ORDER_EXPIRED_MESSAGE',
        description: 'ORDER_EXPIRED_MESSAGE',
        defaultMessage: 'Order has been expired',
    },
    PARTIAL_REFUNDED_MESSAGE: {
        id: 'PARTIAL_REFUNDED_MESSAGE',
        description: 'PARTIAL_REFUNDED_MESSAGE',
        defaultMessage: '{userName} has issued a partial refund',
    },
    ORDER_DROPPED_OFF_MESSAGE: {
        id: 'ORDER_DROPPED_OFF_MESSAGE',
        description: 'ORDER_DROPPED_OFF_MESSAGE',
        defaultMessage: 'Order has been dropped off in store by {userName}',
    }
})


export const RenderNotification = ({ notification }) => {
    const config = {
        CHATS_PENDING: {
            props: {
                translationId: 'CHAT_PENDING_MESSAGE',
                translationParams: {
                    userName: notification.sender_user_name,
                },
            },
            component: ChatPendingMiniNotification,
        },
        HAS_PENDING_TASKS: {
            props: {
                translationId: 'HAS_PENDING_TASKS',
                qtyPendingTask: notification.qtyPendingTask,
            },
            component: PendingTaskMiniNotification,
        },
        ORDER_CONFIRMED: {
            props: {
                translationId: 'ORDER_CONFIRMED_MESSAGE',
                translationParams: {
                    userName: notification.sender_user_name,
                },
            },
            component: OrderMiniNotification,
        },
        ORDER_NOT_CONFIRMED: {
            props: {
                translationId: 'ORDER_NOT_CONFIRMED_MESSAGE',
                translationParams: {
                    userName: notification.sender_user_name,
                },
            },
            component: OrderMiniNotification,
        },
        ORDER_SENT: {
            props: {
                translationId: 'ORDER_SENT_MESSAGE',
                translationParams: {
                    userName: notification.sender_user_name,
                },
            },
            component: OrderMiniNotification,
        },
        ORDER_DROPPED_OFF: {
            props: {
                translationId: 'ORDER_DROPPED_OFF_MESSAGE',
                translationParams: {
                    userName: notification.sender_user_name,
                },
            },
            component: OrderMiniNotification,
        },
        ORDER_RECEIVED: {
            props: {
                translationId: 'ORDER_RECEIVED_MESSAGE',
                translationParams: {
                    userName: notification.sender_user_name,
                },
            },
            component: OrderMiniNotification,
        },
        ORDER_RECEIVED_CONFIRMATION: {
            props: {
                translationId: 'ORDER_RECEIVED_MESSAGE',
                translationParams: {
                    userName: notification.sender_user_name,
                },
            },
            component: OrderMiniNotification,
        },
        ORDER_LOST_CONFIRMATION: {
            props: {
                translationId: 'ORDER_LOST_CONFIRMATION',
                translationParams: {
                    userName: notification.sender_user_name,
                },
            },
            component: OrderMiniNotification,
        },
        ORDER_CANCELLED: {
            props: {
                translationId: 'ORDER_CANCELLED_MESSAGE',
                translationParams: {
                    userName: notification.sender_user_name,
                },
            },
            component: OrderMiniNotification,
        },
        ORDER_VOTED: {
            props: {
                translationId: 'ORDER_VOTED_MESSAGE',
                translationParams: {
                    userName: notification.sender_user_name,
                },
            },
            component: OrderMiniNotification,
        },
        PENDING_PICKUP: {
            props: {
                translationId: 'PENDING_PICKUP_MESSAGE',
                translationParams: {
                    userName: notification.sender_user_name,
                },
            },
            component: OrderMiniNotification,
        },
        ORDER_EXPIRED: {
            props: {
                translationId: 'ORDER_EXPIRED_MESSAGE',
            },
            component: OrderMiniNotification,
        },
        PARTIAL_REFUNDED: {
            props: {
                translationId: 'PARTIAL_REFUNDED_MESSAGE',
                translationParams: {
                    userName: notification.sender_user_name,
                },
            },
            component: OrderMiniNotification,
        },
    }

    const notificationData = config[notification.notification_type]
    if (!notificationData) {
        return null
    }
    return <notificationData.component
        notification={notification} {...notificationData.props}></notificationData.component>
}