import React from 'react'
import { injectIntl } from 'react-intl'
import Translation from '../global/Translation'
import Alerts from '../alerts/Alerts'
import { RenderNotification } from './notification/RenderNotification'


class Notifications extends React.Component {

    componentDidMount() {
        this.props.fetchMyNotifications()
    }

    render() {
        const notifications = this.props.notifications ?? []
        return (
            <div className='row d-flex'>
                <h3>
                    <Translation id='notifications' defaultMessage='Notifications' />
                </h3>
                <div style={{ display: 'flex', flexDirection: 'column', gap: '8px' }}>
                    {notifications.map((notificationIt) => (
                        <RenderNotification key={notificationIt.id} notification={notificationIt} />
                    ))}
                </div>

                {notifications.length === 0 &&
                    <Alerts status='primary' alert='You have no pending notifications'></Alerts>}
            </div>
        )


    }
}


export default injectIntl(Notifications)
