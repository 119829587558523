import React from 'react'
import Translation from '../global/Translation'
import { defineMessages, injectIntl } from 'react-intl'
import AlertsContainer from '../alerts/AlertsContainer'
import Alerts from '../alerts/Alerts'
import Spinner from '../global/Spinner'
import Select from 'react-select'
import { addToSale, editItemFromSale, fetchSaleItemById, storeItemImagesFromSale } from '../../store/sale/actions'
import { findSaleItemById, getIsLoading, getSaleErrorForms, isSending } from '../../store/sale/reducers'
import { connect } from 'react-redux'
import { SearchkitManager } from 'searchkit'
import Button from '../global/Button'
import { getAvailableLangs, getAvailableProductsCategories, getAvailableStatus, getCurrentCollectableContext } from '../../store/products/reducers'
import { withRouter } from 'react-router-dom'
import { FormInput } from '../global/FormInput'
import { FileInput } from '../global/FileInput'
import { ItemPriceInput } from '../shared/items/ItemPriceInput'
import { ItemFeaturesAndDetailsInputs } from '../shared/items/ItemFeaturesAndDetailsInputs'
import { getIsBusiness } from '../../store/login/reducers'
import { setCollectableContextFilter } from '../../store/products/CollectableContext'
import CardSelect from '../shared/card-select/CardSelect'
import moment from 'moment/moment'
import { goBack } from 'react-router-redux'

defineMessages({
    COMMENTS_PLACEHOLDER: {
        id: 'COMMENTS_PLACEHOLDER',
        description: 'COMMENTS_PLACEHOLDER',
        defaultMessage: 'Maximum 30 characters',
    },
    select_the_game_set: {
        id: 'select_the_game_set',
        description: 'select_the_game_set',
        defaultMessage: 'Select the set',
    },
})

const mapStateToProps = (state, ownProps) => {
    return {
        itemPropsOnEdit: ownProps.mode == 'edit' ? findSaleItemById(state, ownProps.match.params.id) : null,
        isSending: isSending(state),
        availableLangs: getAvailableLangs(state),
        availableStatus: getAvailableStatus(state),
        isLoading: getIsLoading(state),
        errorsForm: getSaleErrorForms(state),
        productsCategoriesOptions: getAvailableProductsCategories(state),
        isBusiness: getIsBusiness(state),
        collectableContext: getCurrentCollectableContext(state),
    }
}

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        fetchSaleItemById: (itemId) => {
            return dispatch(fetchSaleItemById(itemId))
        },
        addToSale: (body) => {
            return dispatch(addToSale(body, ownProps.goBackAfterCreate ?? false))
        },
        editItemFromSale: (body) => {
            return dispatch(editItemFromSale(body))
        },
        storeItemImagesFromSale: (buyItemId, images) => {
            return dispatch(storeItemImagesFromSale(buyItemId, images))
        },
        goBack: () => {
            dispatch(goBack())
        },
    }
}
const getSk = (collectableContext, token) => {
    const catalogUri = '/api/catalog/'
    const httpHeaders = token ? { Authorization: 'Bearer ' + token } : {}

    let sk = new SearchkitManager(process.env.REACT_APP_API_HOST + catalogUri, {
        useHistory: false,
        searchUrlPath: '',
        timeout: 60000,
        httpHeaders,
    })

    setCollectableContextFilter(sk, collectableContext)

    return sk
}

class AddToSaleForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            qty: 1,
            productSelectedId: null,
            productSelectedCategory: 'mtg_single',
            selectedImg: null,
            isSigned: false,
            isAltered: false,
            isPlayset: false,
            isRated: false,
            isFoil: false,
            selectedStatus: null,
            selectedLang: null,
            sk: null,
            price: null,
            imageFrontFile: null,
            imageBackFile: null,
            imageFrontSrc: null,
            imageBackSrc: null,
        }
    }

    setSk = () => {
        this.setState(
            {
                sk: getSk(this.props.collectableContext, this.props.token),
            }
            // () => {
            //     if (this.props.location.search.indexOf('promo=true') > -1 && this.props.isLogged && this.props.center !== 7500) {
            //         this.props.changeCenter(7500)
            //     }
            // }
        )
    }

    handleSubmit = (e) => {
        e.preventDefault()
        let body = {
            card: this.state.productSelectedId,
            qty: this.state.qty,
            language: this.state.selectedLang.value,
            status: this.state.selectedStatus.value,
            isSigned: this.state.isSigned,
            isAltered: this.state.isAltered,
            isPlayset: this.state.isPlayset,
            isRated: this.state.isRated,
            isFoil: this.state.isFoil,
            comments: this.commentsInput.value,
            price: this.state.price,
        }

        if (this.props.mode === 'edit') {
            body.id = this.props.itemPropsOnEdit.id
            this.props.editItemFromSale(body).then(() => {
                this.uploadImages(this.props.itemPropsOnEdit.id)
            })
        } else {
            this.props.addToSale(body).then((res) => {
                this.uploadImages(res.id)
            })
        }
    }

    uploadImages = (itemId) => {
        if (!this.state.imageFrontFile && !this.state.imageBackFile) {
            return
        }
        let filesFormData = new FormData()
        if (this.state.imageFrontFile) {
            filesFormData.append('imageFrontFile', this.state.imageFrontFile)
        }
        if (this.state.imageBackFile) {
            filesFormData.append('imageBackFile', this.state.imageBackFile)
        }
        return this.props.storeItemImagesFromSale(itemId, filesFormData)
    }

    canSubmit = () => {
        if (!this.state.productSelectedId) return false
        if (!this.state.qty) return false
        if (!this.state.selectedLang) return false
        if (!this.state.selectedStatus) return false
        if (!this.state.price) return false
        if (this.props.isSending) return false
        return true
    }
    completeFormWithData = () => {
        this.setState(
            {
                qty: this.props.itemPropsOnEdit.qty + (this.props.itemPropsOnEdit.qtyBlocked ?? 0),
                price: this.props.itemPropsOnEdit.price,
                productSelectedId: this.props.itemPropsOnEdit.product.id,
                productSelectedCategory: this.props.itemPropsOnEdit.product.productCategory,
                selectedImg: this.props.itemPropsOnEdit.product.img,
                isSigned: this.props.itemPropsOnEdit.is_signed,
                isAltered: this.props.itemPropsOnEdit.is_altered,
                isPlayset: this.props.itemPropsOnEdit.is_playset,
                isRated: this.props.itemPropsOnEdit.is_graded,
                isFoil: this.props.itemPropsOnEdit.is_foil,
                selectedStatus: this.props.availableStatus.find((item) => item.value == this.props.itemPropsOnEdit.state),
                selectedLang: this.props.availableLangs.find((item) => item.value == this.props.itemPropsOnEdit.language),
                imageFrontSrc: this.props.itemPropsOnEdit.image_front_url,
                imageBackSrc: this.props.itemPropsOnEdit.image_back_url,
            },
            () => {}
        )
    }
    componentDidMount = () => {
        this.setSk()
        if (this.props.mode === 'edit') {
            this.props.fetchSaleItemById(this.props.match.params.id).then((response) => {
                if (this.props.mode === 'edit' && this.props.itemPropsOnEdit) {
                    this.completeFormWithData()
                }
            })
        }
        if (this.props.mode !== 'edit') {
            this.setState({
                selectedLang: { value: 'en', label: 'English' },
                selectedStatus: { label: 'Near Mint', value: 'nearmint' },
            })
        }
        if (this.props.itemOnProductCard) {
            this.setState({
                productSelectedId: this.props.itemOnProductCard.id,
                selectedImg: this.props.itemOnProductCard.img,
                productSelectedCategory: this.props.itemOnProductCard.productCategory,
            })
        }
    }
    componentDidUpdate = (prevProps) => {
        if (this.props.itemPropsOnEdit !== prevProps.itemPropsOnEdit) {
            if (this.props.mode === 'edit' && this.props.itemPropsOnEdit) {
                this.completeFormWithData()
            }
        }
    }
    handleSelectProduct = (product) => {
        this.setState({
            productSelectedId: product.id,
            selectedImg: product.img,
            productSelectedCategory: product.productCategory,
        })
    }

    isStockBlocked = () => {
        return this.props.itemPropsOnEdit && this.props.itemPropsOnEdit.blocked
    }

    render() {
        if (this.props.isLoading) {
            return <Spinner isLoading={true}></Spinner>
        }
        if (!this.state.sk) {
            return null
        }

        const { isLogging } = this.props

        const spinner = isLogging ? <Spinner isLoading={isLogging} /> : null

        const canShowFeatures = true

        return (
            <div>
                <div className="sale">
                    <div className="">
                        {this.isStockBlocked() && (
                            <div>
                                <Alerts
                                    status="warning"
                                    alert={`${this.props.itemPropsOnEdit.qtyBlocked} Items of this product are in someone cart, keep in mind that if you change the price of this item, the original price remains for the user in their active shopping carts, but they wouldnt be able to add more qty to their cart`}
                                ></Alerts>
                                <Alerts status="info" alert={`When there are stock blocked, You can't edit card features such as language, status, ...`}></Alerts>
                            </div>
                        )}

                        <form onSubmit={this.handleSubmit} className="row form-inline">
                            <AlertsContainer />
                            {spinner}
                            {!this.props.onlyForm && (
                                <CardSelect
                                    formMode={this.props.mode}
                                    productsCategoriesOptions={this.props.productsCategoriesOptions}
                                    selectedImg={this.state.selectedImg}
                                    productSelectedId={this.state.productSelectedId}
                                    onlySingle={!this.props.isBusiness}
                                    intl={this.props.intl}
                                    sk={this.state.sk}
                                    handleSelectProduct={this.handleSelectProduct}
                                />
                            )}

                            <div className={`col-12  ${this.props.onlyForm ? 'col-lg-12' : 'col-lg-5'}`}>
                                {!this.props.onlyForm && <h4>
                                    <Translation id="add_item_step_2" defaultMessage="2. Information"></Translation>
                                </h4>}
                                <div className="row mb-3">
                                    <div className="col-4 col-lg-4">
                                        <FormInput
                                            name="qty"
                                            id="qty"
                                            type="number"
                                            showRequired={false}
                                            min={1}
                                            required={true}
                                            setRef={(node) => {
                                                this.qtyInput = node
                                            }}
                                            label={<Translation id="qty" defaultMessage="Quantity" />}
                                            errors={this.props.errorsForm}
                                            value={this.state.qty}
                                            onChange={(e) => this.setState({ qty: e.target.value })}
                                        />
                                        {this.isStockBlocked() && (
                                            <React.Fragment>
                                                <label>Cartas que otros usuarios pueden ver: {this.state.qty - (this.props.itemPropsOnEdit?.qtyBlocked ?? 0)}</label>
                                                <br />
                                                <label>Cartas en el carrito de otros usuarios: {this.props.itemPropsOnEdit?.qtyBlocked ?? 0}</label>
                                            </React.Fragment>
                                        )}
                                    </div>
                                    <div className="col-8 col-lg-4">
                                        <label htmlFor="language">
                                            <Translation id="language" defaultMessage="Language" />
                                        </label>
                                        <Select
                                            className=""
                                            options={this.props.availableLangs}
                                            defaultValue={'en'}
                                            value={this.state.selectedLang}
                                            isDisabled={this.isStockBlocked()}
                                            onChange={(selectedOption) => {
                                                this.setState({ selectedLang: selectedOption })
                                            }}
                                            placeholder={
                                                this.props.intl.formatMessage({
                                                    id: 'select',
                                                }) + '...'
                                            }
                                        />
                                    </div>
                                    <div className="col-12 col-lg-4">
                                        <label htmlFor="status">
                                            <Translation id="status" defaultMessage="Status" />
                                        </label>
                                        <Select
                                            className=""
                                            options={this.props.availableStatus}
                                            defaultValue={'nm'}
                                            value={this.state.selectedStatus}
                                            isDisabled={this.isStockBlocked()}
                                            onChange={(selectedOption) => {
                                                this.setState({ selectedStatus: selectedOption })
                                            }}
                                            placeholder={
                                                this.props.intl.formatMessage({
                                                    id: 'select',
                                                }) + '...'
                                            }
                                        />
                                    </div>
                                </div>
                                {this.props.isBusiness && this.props?.itemOnProductCard?.cardkingdomPrice && (
                                    <div className="row">
                                        <Alerts
                                            alert={`REFERENCE_PRICE`}
                                            values={{
                                                price: parseFloat(this.props.itemOnProductCard.cardkingdomPrice).toFixed(2),
                                                date: moment(this.props.itemOnProductCard.cardkingdomUpdatedAt).format('DD/MM/Y'),
                                            }}
                                        />
                                    </div>
                                )}
                                <ItemPriceInput
                                    errorsForm={this.props.errorsForm}
                                    itemPropsOnEdit={this.props.itemPropsOnEdit}
                                    defaultPrice={this.props.itemPropsOnEdit ? this.props.itemPropsOnEdit.price : null}
                                    setValue={(newPrice) => this.setState({ price: newPrice })}
                                    setRef={(node) => {
                                        this.priceInput = node
                                    }}
                                />
                                {canShowFeatures && (
                                    <ItemFeaturesAndDetailsInputs
                                        isStockBlocked={this.isStockBlocked()}
                                        isSigned={this.state.isSigned}
                                        isPlayset={this.state.isPlayset}
                                        isAltered={this.state.isAltered}
                                        isRated={this.state.isRated}
                                        isFoil={this.state.isFoil}
                                        toggleIsSigned={() => this.setState({ isSigned: !this.state.isSigned })}
                                        toggleIsPlayset={() => this.setState({ isPlayset: !this.state.isPlayset })}
                                        toggleIsAltered={() => this.setState({ isAltered: !this.state.isAltered })}
                                        toggleIsRated={() => this.setState({ isRated: !this.state.isRated })}
                                        toggleIsFoil={() => this.setState({ isFoil: !this.state.isFoil })}
                                        productCategory={this.state.productSelectedCategory}
                                    />
                                )}

                                <div className="row">
                                    <div className="form-group">
                                        <label htmlFor="comments" className="label__input">
                                            <Translation id="comments" defaultMessage="Comments" />
                                        </label>
                                        <input
                                            type="card"
                                            id="inputComments"
                                            autoComplete="off"
                                            className="form-control"
                                            maxLength="30"
                                            disabled={this.isStockBlocked()}
                                            placeholder={this.props.intl.formatMessage({ id: 'COMMENTS_PLACEHOLDER' })}
                                            defaultValue={this.props.itemPropsOnEdit ? this.props.itemPropsOnEdit.comments : null}
                                            ref={(node) => {
                                                this.commentsInput = node
                                            }}
                                        />
                                    </div>
                                </div>

                                <div className="row" style={{ marginTop: '16px',marginBottom:'16px' }}>
                                    <div className="col-6">
                                        <FileInput
                                            id="imageFrontFile"
                                            name="imageFrontFile"
                                            accept="image/png, image/jpeg"
                                            src={this.state.imageFrontSrc}
                                            disabled={this.isStockBlocked()}
                                            label={this.props.intl.formatMessage({ id: 'IMAGE_FRONT' })}
                                            placeholder={this.props.intl.formatMessage({ id: 'IMAGE_FRONT' })}
                                            errors={this.props.errorsForm}
                                            onFileSelect={(newFile) =>
                                                this.setState({
                                                    imageFrontFile: newFile,
                                                    imageFrontSrc: null,
                                                })
                                            }
                                            onFileDelete={() =>
                                                this.setState({
                                                    imageFrontFile: null,
                                                    imageFrontSrc: null,
                                                })
                                            }
                                        />
                                    </div>
                                    <div className="col-6">
                                        <FileInput
                                            id="imageBackFile"
                                            name="imageBackFile"
                                            accept="image/png, image/jpeg"
                                            src={this.state.imageBackSrc}
                                            disabled={this.isStockBlocked()}
                                            label={this.props.intl.formatMessage({ id: 'IMAGE_BACK' })}
                                            placeholder={this.props.intl.formatMessage({ id: 'IMAGE_BACK' })}
                                            errors={this.props.errorsForm}
                                            onFileSelect={(newFile) =>
                                                this.setState({
                                                    imageBackFile: newFile,
                                                    imageBackSrc: null,
                                                })
                                            }
                                            onFileDelete={() =>
                                                this.setState({
                                                    imageBackFile: null,
                                                    imageBackSrc: null,
                                                })
                                            }
                                        />
                                    </div>
                                </div>

                                {this.props.mode === 'edit' ? (
                                    this.canSubmit() ? (
                                        <Button type="submit" onClick={this.handleSubmit}>
                                            <Translation id="edit" defaultMessage="Edit"></Translation>
                                        </Button>
                                    ) : (
                                        <Button disabled>
                                            <Translation id="edit" defaultMessage="Edit"></Translation>
                                        </Button>
                                    )
                                ) : this.canSubmit() ? (
                                    <Button type="submit" onClick={this.handleSubmit}>
                                        <Translation id="add_to_sale" defaultMessage="Add to sale"></Translation>
                                    </Button>
                                ) : (
                                    <Button disabled>
                                        <Translation id="add_to_sale" defaultMessage="Add to sale"></Translation>
                                    </Button>
                                )}
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        )
    }
}

export default withRouter(injectIntl(connect(mapStateToProps, mapDispatchToProps)(AddToSaleForm)))
