import { LOCATION_CHANGE } from 'react-router-redux'

export const initState = {
    orders: null,
    error: null,
    formErrors: null,
    isSending: false,
    isLoading: false,
    orderActions: null,
    order: null,
    orderHead: null
}

const orders = (state = initState, action) => {
    switch (action.type) {
        case 'FETCH_ORDERS_REQUEST':
            return { ...state, error: null, isLoading: true }
        case 'FETCH_ORDERS_SUCCESS':
            return { ...state, orders: action.orders, isLoading: false }
        case 'FETCH_ORDER_BY_ID_REQUEST':
            return { ...state, order: null, orderActions: null, orderHead: null, error: null, isLoading: true }
        case 'FETCH_ORDER_BY_ID_FAILURE':
            return {...state, isLoading: false, error: action.response}
        case 'STORE_ORDER_DROP_OFF_FILE_SUCCESS':
        case 'PARTIAL_REFUND_SUCCESS':
        case 'FETCH_ORDER_BY_ID_SUCCESS':
            return {
                ...state,
                orderHead: action.order.head,
                order: action.order.lines,
                orderActions: action.order.actions,
                isLoading: false
            }
        case 'CHANGE_ORDER_STATUS_REQUEST':
            return { ...state, isSending: true, error: null }
        case 'CHANGE_ORDER_STATUS_FAILURE':
            return { ...state, isSending: false }
        case 'CHANGE_ORDER_STATUS_SUCCESS':
            return { ...state, orderActions: action.order.actions, isSending: false }
        case 'STORE_ORDER_DROP_OFF_FILE_REQUEST':
            return { ...state, isSending: true, error: null, formErrors: null }
        case 'STORE_ORDER_DROP_OFF_FILE_FAILURE':
            return { ...state, isSending: false, formErrors: action.errors }
        case 'LOGOUT':
            return initState
        default:
            return state
    }
}

export const findById = (state, id) => {
    // console.log('sociooo')
    // console.log(id)
    // console.log(state.sale.saleItems)
    if (!state.sale.saleItems || !state.sale.saleItems.length) {
        return null
    }
    let found = state.sale.saleItems.find((item) => {
        return item.id == id
    })
    // console.log(found)
    return found
}
export const getError = (state) => {
    return state.products.error
}

export const getOrders = (state, id) => {
    return state.orders.orders
}
export const getOrderLinesById = (state, id) => {
    return state.orders.order
}
export const getOrderHeadById = (state, id) => {
    return state.orders.orderHead
}
export const getOrderActions = (state, id) => {
    return state.orders.orderActions
}
export const getOrderIsSending = (state) => {
    return state.orders.isSending
}

export const getOrderFormErrors = (state) => {
    return state.orders.formErrors
}

export default orders
