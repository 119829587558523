import React from 'react'
import { injectIntl } from 'react-intl'
import Translation from '../global/Translation'
import Alerts from '../alerts/Alerts'
import { TasksInfo } from './TaskInfo'

class Tasks extends React.Component {

    render() {
        const pendingPay = this.props.pendingTasks['PENDING_PAY'] ?? []
        const pendingConfirm = this.props.pendingTasks['PENDING_CONFIRM'] ?? []
        const pendingConfirmRejection = this.props.pendingTasks['PENDING_CONFIRM_REJECTION'] ?? []
        const pendingSend = this.props.pendingTasks['PENDING_SEND'] ?? []
        const pendingReceive = this.props.pendingTasks['PENDING_RECEIVE'] ?? []
        const pendingVotes = this.props.pendingTasks['PENDING_VOTE'] ?? []
        const pendingValidateBankAccount = this.props.pendingTasks['PENDING_VALIDATE_BANK_ACCOUNT'] ?? []


        return <div className='row d-flex'>
            <h3><Translation id='tasks' defaultMessage='Tasks' /></h3>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '16px' }}>
                {
                    pendingPay.length > 0 &&
                    <TasksInfo key='0' tasks={pendingPay} title='Pending pay'></TasksInfo>
                }
                {
                    pendingConfirm.length > 0 &&
                    <TasksInfo key='1' tasks={pendingConfirm} title='Pending to confirm'></TasksInfo>
                }
                {
                    pendingConfirmRejection.length > 0 &&
                    <TasksInfo key='2' tasks={pendingConfirmRejection}
                               title='Pending to confirm the cancel'></TasksInfo>
                }

                {
                    pendingSend.length > 0 &&
                    <TasksInfo key='3' tasks={pendingSend} title='Pending to send'></TasksInfo>
                }
                {
                    pendingReceive.length > 0 &&
                    <TasksInfo key='4' tasks={pendingReceive} title='Pending to receive'></TasksInfo>
                }
                {
                    pendingVotes.length > 0 &&
                    <TasksInfo key='5' tasks={pendingVotes} title='Pending to vote'></TasksInfo>
                }
                {
                    pendingValidateBankAccount.length > 0 && <TasksInfo key='6' tasks={pendingValidateBankAccount}
                                                                        title='Pending to validate bank account'></TasksInfo>
                }
                {(pendingConfirm.length == 0 &&
                        pendingConfirmRejection.length == 0 &&
                        pendingSend.length == 0 &&
                        pendingReceive.length == 0 &&
                        pendingVotes.length == 0 &&
                        pendingValidateBankAccount.length == 0) &&
                    <Alerts status='primary' alert='You have no pending task'></Alerts>
                }
            </div>
        </div>


    }
}


export default injectIntl(Tasks)
