import React from 'react'

export const NotificationCard = (({urlLinkComponentConfig, iconName, children = {} }) => {
    return <div className='card'>
        <div className='card-body'>
            <urlLinkComponentConfig.component {...urlLinkComponentConfig.props} >
                <div style={{ display: 'flex', flexDirection: 'row', gap: '16px', width: '100%' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <div className='circle bg-color-iron' style={{ marginRight: '15px' }}>
                            <span className='material-icons-outlined'>
                                {iconName}
                            </span>
                        </div>
                    </div>

                    <div style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        {children}
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <div className='border-left-line'>
                            <span className='material-icons-outlined' style={{ padding: '16px' }}>
                                    call_made
                                </span>
                        </div>
                    </div>
                </div>
            </urlLinkComponentConfig.component>
        </div>
    </div>
})